<!-- =========================================================================================
    File Name: VxSidebar.vue
    Description: Sidebar Component
    Component Name: VxSidebar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="parentx">
    <vs-sidebar
      v-hammer:swipe.left="onSwipeLeft"
      ref="mainSidebar"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      default-index="-1"
      class="sidebarx main-menu-sidebar items-no-padding"
      v-model="isSidebarActive"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
    >
      <div @mouseenter="sidebarMouseEntered" @mouseleave="sidebarMouseLeave">
        <div
          class="header-sidebar flex items-end justify-between"
          slot="header"
        >
          <div class="logo flex items-center mx-auto">
            <img
              :src="newLogo + '?token=' + tempToken"
              alt="Logo"
              style="max-width: 100%; max-height: 164px;"
            />
            <!-- <span class="vx-logo-text text-primary" v-show="isMouseEnter || !reduce" v-if="title">
              <img :src="newLogo+'?token='+tempToken" alt="Logo" />
            </span>-->
          </div>
          <!-- <div>
            <template v-if="showCloseButton">
              <feather-icon
                icon="XIcon"
                class="m-0 cursor-pointer"
                @click="$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)"
              ></feather-icon>
            </template>
            <template v-else-if="!showCloseButton && !sidebarItemsMin">
              <feather-icon
                icon="DiscIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="!reduce"
                @click="toggleReduce(true)"
                id="btnSidebarToggler"
              ></feather-icon>
              <feather-icon
                icon="CircleIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="reduce"
                @click="toggleReduce(false)"
                id="btnSidebarToggler"
              ></feather-icon>
            </template>
          </div>-->
        </div>

        <div class="shadow-bottom" v-show="showShadowBottom"></div>

        <VuePerfectScrollbar
          ref="mainSidebarPs"
          class="scroll-area--main-sidebar pt-2"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
        >
          <template v-for="(sidebarItem, index) in sidebarItems">
            <!-- GROUP ITEM HEADER -->

            <span
              :key="`header-${index}`"
              v-if="sidebarItem.header && !sidebarItemsMin"
              class="navigation-header truncate"
              >{{ $t(sidebarItem.i18n) || sidebarItem.header }}</span
            >
            <template v-else-if="!sidebarItem.header">
              <!-- IF IT'S SINGLE ITEM -->
              <!-- checkSideNavPermission([sidebarItem]) && (add this in V-if after Permission activated ) -->
              <!-- &&
                    moduleAccessIds.includes(sidebarItem.module_id) -->

              <!-- {{ sidebarItem.organizationId }}
              {{ OrganizationID }} -->
              <vx-sidebar-item
                ref="sidebarLink"
                :key="`sidebarItem-${index}`"
                v-if="
                  sidebarItem.organizationId.includes(
                    parseInt(OrganizationID)
                  ) &&
                    !sidebarItem.submenu &&
                    (userRoleName == 'Super Admin' &&
                    checkSidebarPerm(
                      sidebarItem.permissions,
                      sidebarItem.contracttype,
                      sidebarItem
                    )
                      ? !sidebarItem.submenu
                      : moduleAccessIds.includes(sidebarItem.module_id) ||
                        (userRoleName == 'Admin' &&
                          checkSidebarPerm(
                            sidebarItem.permissions,
                            sidebarItem.contracttype,
                            sidebarItem
                          ))
                      ? !moduleAccessIds.includes('1012')
                      : moduleAccessIds.includes(sidebarItem.module_id))
                "
                :index="index"
                :to="sidebarItem.slug != 'external' ? sidebarItem.url : ''"
                :href="sidebarItem.slug == 'external' ? sidebarItem.url : ''"
                :icon="sidebarItem.icon"
                :featherIcon="sidebarItem.featherIcon"
                :target="sidebarItem.target"
                :isDisabled="sidebarItem.isDisabled"
                :isSideNav="
                  sidebarItem['subNavs'] ? sidebarItem['subNavs'] : null
                "
              >
                <span v-show="!sidebarItemsMin" class="truncate">
                  {{ $t(sidebarItem.i18n) || sidebarItem.name }}
                </span>
                <vs-chip
                  class="ml-auto"
                  :color="sidebarItem.tagColor"
                  v-if="sidebarItem.tag && (isMouseEnter || !reduce)"
                  >{{ sidebarItem.tag }}</vs-chip
                >
              </vx-sidebar-item>

              <!-- IF HAVE SUBMENU / DROPDOWN -->
              <!-- <template v-else>
                <vx-sidebar-group
                  ref="sidebarGrp"
                  :key="`group-${index}`"
                  :openHover="openGroupHover"
                  :group="sidebarItem"
                  :groupIndex="index"
                  :open="isGroupActive(sidebarItem)"
                ></vx-sidebar-group>
              </template>-->
            </template>
          </template>
        </VuePerfectScrollbar>
      </div>
    </vs-sidebar>
    <div
      v-hammer:swipe.right="onSwipeRightSidebarSwipeArea"
      v-if="!isSidebarActive"
      class="sidebar-swipe-area"
      id="sidebar-swipe-area"
    ></div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VxSidebarGroup from "./VxSidebarGroup.vue";
import VxSidebarItem from "./VxSidebarItem.vue";
import Vue from "vue";
export default {
  name: "vx-sidebar",
  props: {
    sidebarItems: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    logo: {
      type: String,
    },
    parent: {
      type: String,
    },
    openGroupHover: {
      type: Boolean,
      default: false,
    },
    reduceNotRebound: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    OrganizationID: localStorage.getItem("OrganizationID"),
    sindex: 105,
    clickNotClose: false, // disable close sidebar on outside click
    reduce: false, // determines if sidebar is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    isMouseEnter: false,
    settings: {
      // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true,
    },
    windowWidth: window.innerWidth, //width of windows
    showShadowBottom: false,
    //
    newLogo: null,
    newFavicon: null,
    tempToken: window.localStorage.getItem("TempToken"),
    moduleAccessIds: window.localStorage.getItem("moduleAccess")
      ? localStorage.getItem("moduleAccess").split(",")
      : "",
    userRoleName: window.localStorage.getItem("UserRole"),
  }),
  computed: {
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", val);
      },
    },
    reduceSidebar() {
      return Boolean(this.reduce && this.reduceButton);
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton;
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", val);
      },
    },
    sidebarItemsMin() {
      console.log(this.$store.state.sidebarItemsMin, "fsdfdsfsdf");
      return this.$store.state.sidebarItemsMin;
    },
    isGroupActive() {
      return (sidebarItem) => {
        const path = this.$route.fullPath;
        let open = false;
        let func = function(sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach((item) => {
              if (path == item.url) {
                open = true;
              } else if (item.submenu) {
                func(item);
              }
            });
          }
        };
        func(sidebarItem);
        return open;
      };
    },
  },
  watch: {
    reduce(val) {
      if (val == true) {
        this.$store.dispatch("updateSidebarWidth", "reduced");
      } else {
        this.$store.dispatch("updateSidebarWidth", "default");
      }

      setTimeout(function() {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    reduceButton() {
      this.setSidebarWidth();
    },
    $route() {
      if (this.isSidebarActive && this.showCloseButton)
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
    },
  },
  methods: {
    checkSidebarPerm: function(permissions, contracttype, item) {
      if (!window.localStorage.getItem("UserRole")) return false;
      else {
        const role_name = window.localStorage.getItem("UserRole");
        const type = window.localStorage.getItem("UserType");

        if (permissions && permissions.length > 0) {
          if (
            permissions.filter((role) => role.trim() === role_name.trim())
              .length > 0 &&
            (contracttype.indexOf(type) >= 0 ||
              role_name.trim() == "Super Admin")
          ) {
            if (item.name == "CashManagement") {
              return window.localStorage.getItem("isAdvanced") == 1
                ? true
                : false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }

        // return permissions && permissions.length > 0
        //   ? permissions.filter((role) => role.trim() === role_name.trim())
        //       .length > 0 &&
        //       (contracttype.indexOf(type) >= 0 ||
        //         role_name.trim() == "Super Admin")
        //   : false;

        // console.log("role.trim() :", role.trim());
      }
    },
    sidebarMouseEntered() {
      if (this.reduce) this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      this.isMouseEnter = true;
    },
    sidebarMouseLeave() {
      if (this.reduce) {
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
      }
      this.isMouseEnter = false;
    },
    toggleReduce(val) {
      this.reduceButton = val;
      this.setSidebarWidth();
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 992)
          this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "md");
        else this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "lg");

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
        if (this.reduceButton) this.reduce = false;
        // this.reduceButton = false;
        this.showCloseButton = true;
        this.clickNotClose = false;
        this.$store.dispatch("updateSidebarWidth", "no-sidebar");
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      } else {
        this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "xl");
        if (this.reduceButton) this.reduce = true;
        else this.reduce = false;

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
        if (this.reduceButton && !this.isMouseEnter)
          this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
        else this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);

        this.clickNotClose = true;
        this.showCloseButton = false;
        if (this.reduceSidebar)
          this.$store.dispatch("updateSidebarWidth", "reduced");
        else this.$store.dispatch("updateSidebarWidth", "default");
      }
    },
    psSectionScroll() {
      if (this.$refs.mainSidebarPs.$el.scrollTop > 0)
        this.showShadowBottom = true;
      else this.showShadowBottom = false;
    },
    onSwipeLeft() {
      if (this.isSidebarActive && this.showCloseButton)
        this.isSidebarActive = false;
    },
    onSwipeRightSidebarSwipeArea() {
      if (!this.isSidebarActive && this.showCloseButton)
        this.isSidebarActive = true;
    },
  },
  components: {
    VxSidebarGroup,
    VxSidebarItem,
    VuePerfectScrollbar,
  },
  mounted() {
    this.OrganizationID = localStorage.getItem("OrganizationID");
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
    this.newLogo = window.dashboardLogo
      ? window.dashboardLogo
      : "@/assets/images/logo/newlogo/logo-icon.png";
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxSidebar.scss";
</style>
