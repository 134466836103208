/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name:
  Author:
  Author URL:
==========================================================================================*/

let sideBar = [
  {
    url: null,
    name: "Dashboard",
    slug: "dashboard",
    // tag: '2',
    // tagColor: 'warning',
    icon: "HomeIcon",
    featherIcon: true,
    url: "/dashboard/analytics",
    i18n: "Dashboard",
    permissions: ["Super Admin", "Admin", "Admin Manager", "Moderator", "User"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    module_id: "1000",
  },
  {
    url: "/apps/projects",
    name: "Projects",
    featherIcon: true,
    slug: "projects",
    icon: "ClipboardIcon",
    i18n: "Projects",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1001"],
    subNavs: [
      "/companyView",
      "/departmentView",
      "/categoryView",
      "/subCategoryView",
      "/designationView",
      "/employmentView",
      "/projects",
      "/currency",
      "/projectPosList",
      "/editProjectView",
    ],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "1", "2"],
    module_id: "1001",
  },

  {
    url: "/apps/reports",
    name: "Reports",
    featherIcon: true,
    slug: "reports",
    icon: "ClipboardIcon",
    i18n: "Reports",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1001"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "1", "2"],
    module_id: "1016",
  },

  {
    url: "/usersView",
    name: "Users",
    featherIcon: true,
    slug: "team",
    icon: "UsersIcon",
    i18n: "Users",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1002"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "1", "2"],
    module_id: "1002",
  },
  {
    url: "/vendorView",
    featherIcon: true,
    name: "vendorView",
    slug: "team",
    icon: "TruckIcon",
    i18n: "Vendors",
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["1", "2"],
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1003"],
    module_id: "1003",
  },
  // {
  //   url: '/vendorList',
  //   name: 'Vendor',
  //   featherIcon: true,
  //   slug: 'vendor',
  //   icon: 'UsersIcon',
  //   i18n: 'Vendors',
  //   permissions: ['Admin', 'Super Admin', 'Admin Manager', 'Moderator', 'User'],
  // },
  // {
  //   url: '/userRoleView',
  //   name: 'User Roles',
  //   featherIcon: true,
  //   slug: 'team',
  //   icon: 'UnlockIcon',
  //   i18n: 'User Roles',
  // },
  {
    url: "/apps/expenseMasterView",
    name: "Expenses",
    featherIcon: true,
    slug: "expenses",
    icon: "TrendingUpIcon",
    i18n: "Expenses",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1004"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    module_id: "1004",
  },
  // {
  //   url: '/apps/chat',
  //   featherIcon: true,
  //   name: 'Chat',
  //   slug: 'messages',
  //   icon: 'MessageSquareIcon',
  //   i18n: 'Chat',
  // },
  {
    url: "/budgetView",
    featherIcon: true,
    name: "Budget View",
    slug: "team",
    icon: "BriefcaseIcon",
    i18n: "Budget",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1005"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    module_id: "1005",
  },
  // {
  //   url: "/inventoryView",
  //   featherIcon: true,
  //   name: "Inventory View",
  //   slug: "team",
  //   icon: "PackageIcon",
  //   i18n: "Inventory",
  //   permissions: ["Admin", "Admin Manager", "Moderator", "User", "1006"],
  //   organizationId: [ 8,4,5,6],
  //   contracttype: ["0", "2"],
  //   module_id: "1006",
  // },
  // {
  //   url: "/dprAndWorkTypeView",
  //   featherIcon: true,
  //   name: "Daily Cost Report",
  //   slug: "team",
  //   icon: "BarChart2Icon",
  //   i18n: "Daily Cost Report",
  //   organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24],
  //   contracttype: ["1", "2"],
  //   permissions: ["Admin", "Admin Manager", "Moderator", "User"],
  //   module_id: "1009",
  // },

  // {
  //   url: "/dcrbudget",
  //   featherIcon: true,
  //   name: "Daily Cost Report",
  //   slug: "team",
  //   icon: "BarChart2Icon",
  //   i18n: "Daily Cost Report",
  //   organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24],
  //   contracttype: ["1", "2"],
  //   permissions: ["Admin", "Admin Manager", "Moderator", "User"],
  //   module_id: "1030",
  // },

  // {
  //   url: "/contractView",
  //   featherIcon: true,
  //   name: "ContractView",
  //   slug: "team",
  //   icon: "FileTextIcon",
  //   i18n: "Contract",
  //   organizationId: [ 8,4,5,6],
  //   contracttype: ["1", "2"],
  //   subNavs: [
  //     "/contractAllFormView",
  //     "/contractAllFormView",
  //     "/contract/vendorView",
  //     "/contract/usersView",
  //     "/contract/companyView",
  //     "/contract/projectType",
  //     "/contract/projectType",
  //     "/contract/language",
  //     "/contract/showType",
  //     "/contract/category",
  //     "/contract/subCategoryView",
  //     "/contract/reminderType",
  //     "/contract/userAndLevelView",
  //     "/contract/project",
  //     "/contract/agreement",
  //     "/contract/platform",
  //   ],
  //   permissions: ["Admin", "Admin Manager", "Moderator", "User"],
  //   module_id: "1010",
  // },

  {
    url: "/cashManagementView",
    featherIcon: true,
    name: "CashManagement",
    slug: "team",
    icon: "PocketIcon",
    i18n: "Petty Cash Request",
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "1", "2"],
    // subNavs: ['/contractAllFormView', '/contractAllFormView'],
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1013"],
    module_id: "1013",
  },
  {
    url: "/vendorAdvanceRequestView",
    featherIcon: true,
    name: "VAR",
    slug: "team",
    icon: "GitPullRequestIcon",
    i18n: "Vendor Advance Request",
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "1", "2"],
    // subNavs: ['/contractAllFormView', '/contractAllFormView'],
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1014"],
    module_id: "1014",
  },
  {
    url: "/apps/creditNoteMasterView",
    name: "Credit Note",
    featherIcon: true,
    slug: "CreditNote",
    icon: "FilePlusIcon",
    i18n: "Credit Note",
    permissions: ["Admin", "Admin Manager", "Moderator", "User", "1015"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    module_id: "1015",
  },
  {
    url: "/master",
    featherIcon: true,
    name: "CashManagement",
    slug: "team",
    icon: "SettingsIcon",
    i18n: "Settings",
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    subNavs: [
      "/master",
      "/master/priority",
      "/master/gst",
      "/master/paymentMode",
    ],
    permissions: ["Admin", "Admin Manager", "Moderator", "User"],
    module_id: "1008",
  },

  //  {
  //   url: '/workTypeView',
  //   featherIcon: true,
  //   name: 'workTypeView',
  //   slug: 'team',
  //   icon: 'PackageIcon',
  //   i18n: 'Work Type',
  //   permissions: ['Admin', 'Admin Manager', 'Super Admin']
  // },
  // {
  //   url: null,
  //   name: "Inventory",
  //   slug: "inventory",
  //   icon: "PackageIcon",
  //   url: '/inventory',
  //   i18n: "Inventory",
  //   permissions: ['Admin', 'Super Admin'],
  //   submenu: [{
  //       name: "Inventory",
  //       slug: "inventory",
  //       icon: "PackageIcon",
  //       url: '/inventory',
  //       i18n: "Inventory",
  //       permissions: ['Admin', 'Super Admin'],
  //     },
  //     {
  //       name: "Inventory",
  //       slug: "inventory",
  //       icon: "PackageIcon",
  //       url: '/inventory',
  //       i18n: "Inventory",
  //       permissions: ['Admin', 'Super Admin'],
  //     },
  //   ],
  // },
  {
    url: "/",
    featherIcon: true,
    name: "User Permission",
    slug: "team",
    icon: "SettingsIcon",
    i18n: "User Permission",
    permissions: ["Super Admin"],
    organizationId: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    contracttype: ["0", "2"],
    module_id: "1011",
  },
  {
    url: "/Organization",
    featherIcon: true,
    name: "Organization",
    slug: "team",
    icon: "SettingsIcon",
    i18n: "Organization",
    permissions: ["Super Admin"],
    organizationId: [1],
    contracttype: ["0"],
    module_id: "1012",
  },

  // {
  //   url: '/customizeFieldView',
  //   featherIcon: true,
  //   name: 'Custom Fields',
  //   slug: 'team',
  //   icon: 'SettingsIcon',
  //   i18n: 'Custom Fields',
  //   permissions: ['Admin', 'Super Admin', 'Admin Manager', 'Moderator'],
  // },
  // {
  //   url: "/management",
  //   featherIcon: true,
  //   name: "Management",
  //   slug: "team",
  //   icon: "SettingsIcon",
  //   i18n: "Management",
  //   organizationId: [ 8,4,5,6],
  //   contracttype: ["0", "2"],
  //   permissions: ["Admin", "Admin Manager", "Moderator", "User"],
  //   module_id: "1008",
  // },
];

export default sideBar;
